export const movingTruck = (function(){
	let my = {};

	function _assignListeners(){
		[].forEach.call(document.querySelectorAll('#moving_truck'), (button) => {
			button.addEventListener('click', _toggleItem);
		});
	}

	function _toggleItem(){
		//const moving_button = e.currentTarget;
		const overlay = document.getElementById('overlay');
		const truck_modal = document.getElementById('truck_modal');
		overlay.classList.toggle('active');
		truck_modal.classList.toggle('active');
	}

	function _handleOverlay() {
		const overlay = document.getElementById('overlay');
		const truck_modal = document.getElementById('truck_modal');

		overlay.addEventListener('click', () => {
			overlay.classList.remove('active');
			truck_modal.classList.remove('active');
		});
	}

	my.init = function(){
		if(document.getElementById('moving_truck')) {
			_assignListeners();
			_handleOverlay();
		}
	};

	return my;
})();
