export const navToggle = (function(){
	let my = {};

	function _mobileToggle() {
		document.getElementById('site_nav_toggle').addEventListener('click', function(e){
			let btn = e.currentTarget;
			let nav = document.getElementById('site_header_nav');

			if(btn.classList.contains('open')) {
				btn.classList.remove('open');
				nav.classList.remove('open');
				console.log('deleting open');
			} else {
				btn.classList.add('open');
				nav.classList.add('open');
				console.log('adding open');
			}
		});
	}

	my.init = function(){
		_mobileToggle();
	};

	return my;
})();
